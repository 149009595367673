<template>
    <Loading :isLoading="isLoading" />
    <html>
      <body class="" style="background: #FFF">
        <Header :localNavigation="false" />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
           
            <div class="bg-container col-span-6">
                <div class="w-full md:w-4/5 mx-auto h-auto py-16 grid grid-cols-1 md:grid-cols-2">
                    <div class="px-10 md:px-5 lg:px-20">
                        <p class="text-3xl font-bold mb-10 RobotoBold">Finalizar Trámite con envío de firma</p>
                        <p class="text-2xl font-light mb-5 RobotoRegular text-gray-600">¡Su trámite se ha procesado con éxito!</p>
                        <p class="text-xl font-light mb-14 description">
                            Elija el medio por el cual desea enviar su contrato para firma.
                        </p>
                        <input type="button" id="button_sign_local" v-show="!isActive" class="bg-mustardDark cursor-pointer text-white px-5 py-2 mx-auto my-2 w-full md:w-full h-14 md:h-14  rounded text-center font-medium text-xl" @click="sendMail()" value="Firmar ahora de forma local" /> 
                        <input type="button" id="button_send_sms" v-show="!isActive" class="bg-mustardDark cursor-pointer text-white px-5 py-2 mx-auto my-2 w-full md:w-full h-14 md:h-14  rounded text-center font-medium text-xl" @click="sendSMS()" value="Enviar por SMS" />  
                    
                    </div>
                    <div class="px-10 py-16 hidden md:block">
                        <img :src="require('@/assets/Cuenta/vidaSimple/finished.svg')" alt="FONCABSA SFC" class="mx-auto">
                    </div>
                </div>
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import Issuance from '@/classes/Issuance';

import { onMounted, onBeforeMount, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';

import Loading from '@/components/Loading/VueLoading.vue';

export default {
    setup(){
        const router = useRouter();
        const route = useRoute();
        const issuanceObject = ref({});
        const issuanceInformation = ref({});
        const host = window.location.host;
        const role = ref("");
        const productName = ref("");

        let isActive = ref(false);
        let isLoading = ref(true);

        const sessionStoraceList = [
            "user-information",
            "user-detail"
        ]

        const localStoraceList = [
            "quote-description",
            "quote-vida-total",
            "quote-vida-simple",
            "quote-contact",
            "quote-medical-operations",
            "quote-smokes",
            "quote-coverage",
            "quote-sports",
            "quote-alcohol",
            "quote-personal-data",
            "quote-diseases",
            "quote-hobbies",
            "quote-recruitment-data",
            "quote-occupations",
            "quote-detail",
            "issuance-vida-total",
            "issuance-vida-simple",
            "issuance-address",
            "issuance-labor-information",
            "issuance-collection",
            "issuance-beneficiaries",
            "document_ine",
            "document_proof_of_address",
            "issuance-detail"
        ];

        onBeforeMount(async ()=>{
            issuanceObject.value = new Issuance();
            loadIssuanceInformation();
            role.value = route.params.role;
            productName.value = route.params.product;
        });

        onMounted(() => {
            isLoading.value = false;
        })

        const clearStorage = () => {
            localStoraceList.forEach(function(key) {
                localStorage.removeItem(key); // Cambiar a sessionStorage si corresponde
            });
            sessionStoraceList.forEach(function(key) {
                sessionStorage.removeItem(key);
            })
        }

        const loadIssuanceInformation = () => {
            issuanceInformation.value = getLocalStorage('issuance-detail');
        }

        const sendMail = async () => {
            isLoading.value = true;
            isActive.value = true;

            const rfc = issuanceInformation.value.vs_applicant.rfc;
            const vsIssuePolicyId = issuanceInformation.value.vs_issue_policy.id;
            const solicitudId = issuanceInformation.value.id;
            const urlBack = `http://${host}/contratacion/exitosa/`;
            const redirectUrl = `/${role.value}/productos/${productName.value}`;

            productName.value = productName.value.replace("-", " ").toLowerCase();
            const response = await issuanceObject.value.sendDocumenToSigned({product_name: productName.value , rfc: rfc, vs_issue_policy_id: vsIssuePolicyId, solicitud_id: solicitudId, url_back: urlBack}).then(response => {
                return { status: true, data: response.data.response.data, message: response.data.response.message };
            }).catch(error=> {
                return { status: false, data: null, message: error.data.response.message };
            });

            if(!response.status){
                isLoading.value = false;
                isActive.value = false;

                showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', response.message);
                return;
            }

            isLoading.value = false;
            window.open(response.data);
            showMessage('info', 'Firmado de forma local', 'Favor de dirigirse a la nueva ventana,<br>para continuar con el proceso de firmado de su solicitud', 'OK', '#FEB72B', redirectUrl);
        }

        const sendSMS = async () => {
            isLoading.value = true;
            isActive.value = true;

            const rfc = issuanceInformation.value.vs_applicant.rfc;
            const vsIssuePolicyId = issuanceInformation.value.vs_issue_policy.id;
            const solicitudId = issuanceInformation.value.id;
            const urlBack = `http://${host}/contratacion/exitosa/`;
            const redirectUrl = `/${role.value}/productos/${productName.value}`;

            productName.value = productName.value.replace("-", " ").toLowerCase();
            let response = await issuanceObject.value.sendDocumenToSigned({product_name: productName.value , rfc: rfc, vs_issue_policy_id: vsIssuePolicyId, solicitud_id: solicitudId, url_back: urlBack}).then(response => {
                return { status: true, data: response.data.response.data, message: response.data.response.message };
            }).catch(error=> {
                return { status: false, data: null, message: error.data.response.message };
            });

            if(!response.status){
                isLoading.value = false;
                isActive.value = false;

                showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', response.message);
                return;
            }

            const token = getToken('login');
            const phoneNumber = issuanceInformation.value.vs_applicant.cellphone;
            const message = response.data;
            response = await issuanceObject.value.sendSMS({token: token, phone_number: phoneNumber, message: message}).then(response => {
                return { status: true, data: response.data.response.data, message: response.data.response.message };
            }).catch(error => {
                return { status: false, data: null, message: error.data.response.message };
            })

            if(!response.status){
                isLoading.value = false;
                isActive.value = false;

                showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', response.message);
                return;
            }

            isLoading.value = false;
            showMessage('info', 'SMS enviado correctamente', 'Favor de ingresar al enlace enviado por sms a su celular,<br>para continuar con el proceso de firmado de su solicitud', 'OK', '#FEB72B', '/cuenta/principal');
        }

        const getLocalStorage = (name) => {
            return JSON.parse(localStorage.getItem(name));
        }

        const getToken = (name) => {
            return sessionStorage.getItem(name);
        }

        const showMessage = (type, title, message, confirmButtonText = "OK", confirmButtonColor= "#FEB72B", redirectTo = "") => {
            var Swal = require('sweetalert2');
            Swal.fire({
                title: title,
                html: message,
                icon: type,
                confirmButtonText: confirmButtonText,
                confirmButtonColor: confirmButtonColor
            }).then(
                () => {
                    if(redirectTo != "") {
                        clearStorage();
                        router.push(redirectTo);
                    }
                }
            );
        }

        return {
            sendMail,
            sendSMS,
            isActive,
            isLoading
        }
    },
    components:{
        Loading,
        Header,
        MenuLateralMobile,
        MenuLateral
    }
}
</script>

<style scoped>
.description {
    font-family: 'roboto-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #485462;
}
</style>